import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import EFROFC from '../assets/images/subsidie/Logo-EFROFC-600x600_0.png';
import SNNFC from '../assets/images/subsidie/Logo-SNNFC-600x600_0.png';

const Subsidie = (page: PageProps) => {
  const { t } = useTranslation('subsidie');
  return (
    <Layout>
      <Seo page={page} title="Subsidie" />
      <main className="page-privacy-policy">
        <section className="section">
          <div className="container">
            <div
              className="section__content"
              dangerouslySetInnerHTML={{ __html: t('data', { EFROFC, SNNFC }) }}
            />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Subsidie;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
